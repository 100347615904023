import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import classNames from "classnames/bind";
import {renderInput} from "utils/forms/renderers";
import {email, required} from "utils/forms/validators";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

export class NewsletterSubscribeForm extends Component {
  render() {
    const {isLoading, errors, refRecaptcha} = this.props;

    return (
        <form className="form" onSubmit={this.props.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name <span className="required">*</span></label>
            <Field
                id="name"
                name="name"
                component={renderInput}
                type="text"
                className="form-control"
                validate={[required]}
                placeholder="Type your name"/>
          </div>
          <div className={classNames({
            "form-group": true,
            "has-error": errors.email
          })}>
            <label htmlFor="email">Email <span className="required">*</span></label>
            <Field
                id="email"
                name="email"
                component={renderInput}
                type="email"
                className="form-control"
                validate={[required, email]}
                placeholder="Type your email address"/>
            {errors.email && errors.email.map((msj, index) => (
                <span className="help-block" key={index}>{msj}</span>
            ))}
          </div>
          <Recaptcha
              refRecaptcha={refRecaptcha}
              afterCheckedRecaptcha={this.props.afterCheckedRecaptcha}
              onExpiredRecaptcha={this.props.onExpiredRecaptcha}/>
          <br/>
          <div className="form-group">
            {
              isLoading ? (
                  <input
                      type="submit"
                      value="SUBSCRIBING..."
                      disabled={true}/>
              ) : (
                  <input
                      type="submit"
                      value="SUBSCRIBE"/>
              )
            }
          </div>
        </form>
    )
  }
}

const NewsletterSubscribeReduxForm = reduxForm({
  form: 'newsletterSubscribeForm'
})(NewsletterSubscribeForm);

export default withRecaptcha(NewsletterSubscribeReduxForm)