import React, {Component} from 'react';
import SignUpForm from "./components/SignUpForm";
import {updateCartOwner} from "services/store";
import {Redirect} from "react-router-dom";
import {signUp} from "services/auth/index";
import SEO from "components/SEO/index";
import AuthSocialButtons from "scenes/Auth/scenes/Login/components/AuthSocialButtons/index";

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleAfterSocialSubmit = this.handleAfterSocialSubmit.bind(this);
    this.refRecaptcha = React.createRef();
  }

  // TODO Use this function to convert the cart when the cart logic is implemented on this application
  convertCart() {
    const promise = updateCartOwner();
    promise.then(response => this.props.fetchCartItemsSuccess(response.data));
  }

  signUp(data) {
    const promise = signUp({
      ...data,
      recaptcha: this.refRecaptcha.current.getValue()
    });
    promise.then(response => {
      this.setState({
        loading: false
      });
      this.props.login(response.data);
    });
    promise.catch(error => {
      this.refRecaptcha.current.reset();
      this.setState({
        loading: false,
        errors: error.response.data
      });
    });
    return promise;
  }

  handleFormSubmit(data) {
    this.setState({
      loading: true
    }, () => {
      this.signUp(data);
    });
  }

  handleAfterSocialSubmit(response) {
    this.props.login(response.data);
  }

  render() {
    const {authReducer} = this.props;
    const {loading, errors} = this.state;

    if (authReducer.isAuthenticated) {
      return (
          // TODO redirect to profile page
          <Redirect to="/"/>
          // <Redirect to="/my-account/profile"/>
      );
    }

    return (
        <div className="container">
          <div className="wrapper wrapper--bg-auth-form">
            <AuthSocialButtons
                onAfterSubmit={this.handleAfterSocialSubmit}/>
            <SignUpForm
                onSubmit={this.handleFormSubmit}
                refRecaptcha={this.refRecaptcha}
                isLoading={loading}
                errors={errors}/>
          </div>
          <SEO url='auth/signup'/>
        </div>
    )
  }
}

export default SignUp;
