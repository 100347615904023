import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field, FormSection, reduxForm} from 'redux-form';
import {renderCheckbox, renderCreditCardInput, renderInput, renderRadio} from "utils/forms/renderers";
import {
  creditCardCvv, creditCardCvvData,
  creditCardMonth, creditCardMonthData, creditCardNumber, creditCardYear, creditCardYearData, onlyText,
  required
} from "utils/forms/validators";
import CreditCardInfo from "utils/components/CreditCardInfo/index";
import "./styles/CreditCardForm.scss";
import AddressInfo from "utils/components/AddressInfo/index";
import AddressFieldsContainer
  from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressFields/container/index";
import SelectedAddress from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/SelectedAddress/index";
import Recaptcha from "components/Recaptcha";

/**
 * Renders a form field (regular input component by default)
 * @param label
 * @param type
 * @param placeholder
 * @param fieldName
 * @param validations
 * @param parse
 * @param component
 * @return {*}
 * @constructor
 */
const RenderField = ({label, type, placeholder, fieldName, validations, parse, component}) => {
  return (
      <div className="form-group">
        <label htmlFor={fieldName}>{label} <span className="required">*</span></label>
        <Field id={fieldName}
               name={fieldName}
               component={component || renderInput}
               type={type}
               className="form-control"
               placeholder={placeholder}
               validate={validations}
               parse={parse}/>
      </div>
  );
};

RenderField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  validations: PropTypes.array,
  parse: PropTypes.func,
  component: PropTypes.node
};

/**
 * Renders a credit card form field
 * @return {*}
 * @constructor
 */
const RenderCreditCardField = () => {
  /**
   * Parses credit card number to numbers only
   * @param creditCardNumber
   */
  const parseCreditCardNumber = (creditCardNumber) => {
    return creditCardNumber.replace(/[ ]/g, "");
  };

  return (
      <RenderField
        fieldName="number"
        validate={[required, creditCardNumber]}
        className="form-control"
        type="tel"
        placeholder="Type your credit card number"
        parse={value => parseCreditCardNumber(value)}
        component={renderCreditCardInput}
        label="Type your credit card number"/>
  );
};

export class CreditCardForm extends Component {
  constructor(props) {
    super(props);

    let addresses = [...this.props.myAccountReducer.addresses];

    if (this.props.myAccountReducer.selectedCreditCard && this.props.myAccountReducer.selectedCreditCard.billing_address) {
      const addressExists = addresses.some(address => address.id === this.props.myAccountReducer.selectedCreditCard.billing_address);
      if (!addressExists) {
        addresses.push(this.props.myAccountReducer.selectedCreditCard.billing_address_data);
      }
    }


    this.state = {
      addressListIsOpen: false,
      addressFormIsOpen: false,
      selectedAddressId: null,
      reCaptchaIsValid: false,
      addresses: addresses
    };

    this.handelCancelButtonClick = this.handelCancelButtonClick.bind(this);
    this.changeSelectedAddressId = addressId => this._changeSelectedAddressId.bind(this, addressId);
    this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
    this.handleOpenAddressForm = this.handleOpenAddressForm.bind(this);
    this.handleCloseAddressForm = this.handleCloseAddressForm.bind(this);
    this.handleByDefaultCheck = this.handleByDefaultCheck.bind(this);
  }

  componentDidMount() {
    const {selectedCreditCard} = this.props.myAccountReducer;
    this.props.changeFieldValue('cc_type', 1);

    if (this.hasAddresses) {
      if (selectedCreditCard && selectedCreditCard.billing_address)
        this._changeSelectedAddressId(selectedCreditCard.billing_address);
      else
        this._changeSelectedAddressId(this.state.addresses[0].id);
    }
  }

  /**
   * Handles close modal
   */
  handelCancelButtonClick() {
    if (this.props.onCancelButtonClick)
      this.props.onCancelButtonClick()
  }

  /**
   * Indicates if the modal is editing
   */
  isEdition() {
    return this.props.initialValues && this.props.initialValues.id;
  }

  /**
   * Verify if has added addresses
   * @return {boolean}
   */
  get hasAddresses() {
    const {addresses} = this.state;
    return addresses && addresses.length > 0;
  }

  /**
   * Change selected address Id
   * @param addressId
   */
  _changeSelectedAddressId(addressId) {
    setTimeout(() => {
      this.props.changeFieldValue('billing_address', addressId);
    }, 0);
    this.setState({
      selectedAddressId: addressId
    });
  }

  handleChangeBillingAddress() {
    this.setState({addressListIsOpen: true})
  }

  /**
   *
   */
  handleOpenAddressForm() {
    this.setState({
      addressFormIsOpen: true
    }, () => {
      this.reinitializeAddressForm();
    });
  }

  /**
   * Close address form
   */
  handleCloseAddressForm() {
    this.setState({
      addressFormIsOpen: false
    }, () => {
      this.reinitializeAddressForm();
    });
  }

  /**
   * Reinitialize address form
   */
  reinitializeAddressForm() {
    // Reset address form
    this.props.resetAddressForm();

    // Clear errors of address form
    // this.setState({
    //   addressErrors: []
    // });
  }

  handleByDefaultCheck(event) {
    const {addressFormIsOpen} = this.state;

    this.props.changeFieldValue('address', null);
    this.setState({
      addressFormIsOpen: !addressFormIsOpen
    })
  }

  render() {
    const {isLoading, handleSubmit, success, errors, addressErrors, withBillingInfo, withSubmitButtons, refRecaptcha} = this.props;
    const creditCard = this.props.initialValues;
    const {addressListIsOpen, addressFormIsOpen, selectedAddressId, addresses} = this.state;

    return (
        <form id="credit-card-form"
              className="form"
              onSubmit={handleSubmit}>
          {
            this.isEdition() && (
                <div className="row">
                  <div className="col-md-6">
                    <h2>Credit Card Info</h2>
                    <CreditCardInfo creditCard={creditCard}/>
                  </div>
                </div>
            )
          }
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <RenderField label="Name on Card"
                             fieldName="full_name"
                             type="text"
                             placeholder="Type your name on card"
                             validations={[required, onlyText]}/>
              </div>
            </div>
            {
              !this.isEdition() && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <RenderCreditCardField/>
                      {
                        errors["non_field_errors"] && errors["non_field_errors"].map((msj, index) => (
                            <span className="help-block" key={`creditCardNumberError${index}`}>{msj}</span>
                        ))
                      }
                    </div>
                  </div>
              )
            }
          </div>
          <div className="row">
            <div className="col-md-4">
              <RenderField label="Expiration Month"
                           fieldName="exp_mo"
                           type="number"
                           placeholder="12"
                           validations={[required, creditCardMonth, creditCardMonthData]}/>
            </div>
            <div className="col-md-4">
              <RenderField label="Expiration Year"
                           fieldName="exp_yr"
                           type="number"
                           placeholder="25"
                           validations={[required, creditCardYear, creditCardYearData]}/>
            </div>
            <div className="col-md-4">
              <RenderField label="CVV Code"
                           fieldName="verification_value"
                           type="password"
                           placeholder="CCV code"
                           validations={[required, creditCardCvv, creditCardCvvData]}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              {
                withBillingInfo ?
                    <div className="form-group credit-card-form__use-as-default">
                      <div className="custom-checkbox">
                        <Field
                            component={renderCheckbox}
                            name="byDefault"
                            id="byDefault"/>
                      </div>
                      <label
                          className="by_default"
                          htmlFor="byDefault">
                        Use as default
                      </label>
                    </div>
                    :
                    <div className="form-group credit-card-form__use-as-default">
                      <div className="custom-checkbox">
                        <Field
                            component={renderCheckbox}
                            name="byDefault"
                            id="byDefault"
                            checked={!addressFormIsOpen}
                            onChange={this.handleByDefaultCheck}/>
                      </div>
                      <label
                          className="by_default"
                          htmlFor="byDefault">
                        Use shipping address as billing address
                      </label>
                    </div>
              }
            </div>
          </div>
          {
            withBillingInfo &&
            <div>
              {
                !addressListIsOpen ?
                    <div className="billing-address">
                      <h2 className="credit-card-form__billing-info">Billing Address</h2>
                      {
                        this.hasAddresses && selectedAddressId &&
                        <div>
                          <SelectedAddress
                              address={addresses.find(item => item.id === selectedAddressId)}/>
                          <div className="billing-address__change-link">
                            <a onClick={this.handleChangeBillingAddress}>Change</a>
                          </div>
                        </div>
                      }
                      <hr/>
                    </div>
                    :
                    <div className="billing-info">
                      <h2 className="credit-card-form__billing-info">Billing Info</h2>
                      <div className="form-group credit-card-form__address-button">
                        {
                          !addressFormIsOpen && this.hasAddresses
                              ?
                              <button
                                  onClick={this.handleOpenAddressForm}
                                  type="button"
                                  className="btn-custom">
                                ADD NEW ADDRESS
                              </button>
                              :
                              <button
                                  onClick={this.handleCloseAddressForm}
                                  type="button"
                                  className="btn-custom use-saved-address--button">
                                USE SAVED ADDRESS
                              </button>
                        }
                      </div>
                      {
                        !addressFormIsOpen && this.hasAddresses &&
                        <div className="form-group">
                          <ul className="radio credit-card-form__address-list">
                            {
                              addresses.map(address => (
                                  <label
                                      key={`${address.id}`}
                                      htmlFor={`address_${address.id}`}>
                                    <li>
                                      <Field
                                          name="billing_address"
                                          id={`address_${address.id}`}
                                          component={renderRadio}
                                          validate={[required]}
                                          value={address.id}
                                          checked={selectedAddressId ? selectedAddressId === address.id : false}
                                          onChange={this.changeSelectedAddressId(address.id)}/>
                                      <label htmlFor={`address_${address.id}`}>
                                        <AddressInfo
                                            address={address}/>
                                      </label>
                                    </li>
                                  </label>
                              ))
                            }
                          </ul>
                        </div>
                      }
                    </div>
              }
            </div>
          }
          {
            (addressFormIsOpen || !this.hasAddresses) &&
            <FormSection
                name="address">
              <AddressFieldsContainer
                  removeOptions={true}
                  changeFieldValue={this.props.changeFieldValueForAddress}
                  errors={addressErrors}
              />
            </FormSection>
          }
          <div style={{
            textAlign: "center",
            marginBottom: "15px"
          }}>
            <div style={{opacity: 0}}>
              <Field
                  component={renderInput}
                  name="recaptcha"
                  id="recaptcha"/>
            </div>
            <Recaptcha
                refRecaptcha={refRecaptcha}
                afterCheckedRecaptcha={token => {
                  this.setState({reCaptchaIsValid: true});
                  this.props.changeRecaptcha(token);
                }}
                onExpiredRecaptcha={error => console.log(error)}/>
          </div>
          <div className="align-right">
            {
              withSubmitButtons &&
              <div className="form-group">
                {
                  isLoading ? (
                      <input type="submit" value="SAVING..." disabled={true}/>
                  ) : (
                      success ? (
                          <button type="submit" disabled={true}>
                            <i className="fa fa-check"/> CARD WAS SAVED!
                          </button>
                      ) : (
                          <button disabled={!this.state.reCaptchaIsValid} type="submit">
                            SAVE CHANGES
                          </button>
                      )
                  )
                }
                <br/>
                <br/>
                <button type="button" className="btn btn-gray btn-block" onClick={this.handelCancelButtonClick}>CANCEL
                </button>
              </div>
            }
          </div>
        </form>
    )
  }
}

CreditCardForm.defaultProps = {
  withBillingInfo: true,
  withSubmitButtons: true
};

CreditCardForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func,
  withBillingInfo: PropTypes.bool,
  withSubmitButtons: PropTypes.bool
};

const CreditCardReduxForm = reduxForm({
  form: 'accountCreditCardForm'
})(CreditCardForm);

export default CreditCardReduxForm
